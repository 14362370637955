import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Head from '../components/Head/Head';
import { Button, Group } from '@mantine/core';
import { StarFilledIcon, TwitterLogoIcon, EnvelopeClosedIcon, ExclamationTriangleIcon } from '@modulz/radix-icons';
export const _frontmatter = {
  "title": "404",
  "slug": "/404"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GatsbyLink = makeShortcode("GatsbyLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head title="404" disableTitleTemplate mdxType="Head" />
    <h1 {...{
      "id": "congratulations"
    }}>{`Congratulations!`}</h1>
    <p>{`You've found a completely secret place on Mantine website. Unfortunately, this is only a 404 page.
If you've landed on this page from link on Mantine website, please `}<a parentName="p" {...{
        "href": "https://github.com/mantinedev/mantine/issues/new"
      }}>{`create an issue`}</a>{`.
Otherwise, here are some learning materials that will help you get started with Mantine:`}</p>
    <ul>
      <li parentName="ul">
        <GatsbyLink to="/pages/contribute/" mdxType="GatsbyLink">Mantine contributions guideline</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/guides/ssr/" mdxType="GatsbyLink">Learn how to setup server side rendering with Mantine</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/others/notifications/" mdxType="GatsbyLink">Mantine notifications system</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/pages/theming/" mdxType="GatsbyLink">Learn how to customize Mantine theme</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/hooks/use-merged-ref/" mdxType="GatsbyLink">Learn how to merge react refs into single one</GatsbyLink>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLNYkxOF6rcICWx0C9LVWWVqvHlYJyqw7g"
        }}>{`Watch 30 A11ycasts with Rob Dodson about accessibility`}</a></li>
    </ul>
    <p>{`And since you are here, what about following Mantine on Twitter and a star on Github 😉?`}</p>
    <Group grow mdxType="Group">
  <Button component="a" href="https://github.com/mantinedev/mantine" leftIcon={<StarFilledIcon mdxType="StarFilledIcon" />} color="gray" variant="outline" mdxType="Button">
    Star mantine on Github
  </Button>
  <Button component="a" href="https://twitter.com/mantinedev" leftIcon={<TwitterLogoIcon mdxType="TwitterLogoIcon" />} color="blue" variant="outline" mdxType="Button">
    Follow Mantine on Twitter
  </Button>
  <Button component="a" href="https://buttondown.email/mantine" leftIcon={<EnvelopeClosedIcon mdxType="EnvelopeClosedIcon" />} color="blue" variant="outline" mdxType="Button">
    Subscribe to monthly newsletter
  </Button>
    </Group>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      